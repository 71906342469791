/* 전체적인 레이아웃 스타일 */
.search-results-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  background-color: lightgray;
}

/* 첫 번째 줄의 카드들이 한 줄에 들어가도록 조정 */
.cards-container {
  display: flex;
  justify-content: space-around; /* 간격을 균등하게 조정 */
  flex-wrap: wrap;
  gap: 20px;
  width: 70%;
}

/* 카드 및 관련 요소의 스타일 */
.alert-card,
.country-card,
.time-card,
.exchange-card {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2); /* 그림자 효과 추가 */
  transition: 0.3s; /* 부드러운 효과 */
  border-radius: 10px; /* 모서리 둥글게 */
}

.alert-card:hover,
.country-card:hover,
.time-card:hover,
.exchange-card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2); /* 호버 시 그림자 강조 */
}

/* 모바일 환경에서 카드 너비를 100%로 조정 */
@media (max-width: 768px) {
  .alert-card,
  .country-card,
  .time-card,
  .combined-card {
    width: 100%;
  }
  
  .exchange-card {
    max-height: none; /* 모바일 환경에서는 세로 크기 제한 해제 */
  }
}

/* 데스크탑 환경에서 첫 번째 줄 카드 너비 조정 */
@media (min-width: 768px) {
  .alert-card,
  .combined-card {
    width: calc(50% - 20px); /* 첫 번째 줄에 두 카드가 들어가도록 조정 */
  }

  .country-card,
  .time-card {
    width: calc(50% - 20px); /* 첫 번째 줄에 두 카드가 들어가도록 조정 */
  }
}

/* combined-card 스타일 추가 */
.combined-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}

/* combined-card 내부 섹션 스타일 */
.combined-card .country-section,
.combined-card .time-section {
  flex: 1;
  padding: 10px;
}

/* 로딩 및 에러 메시지 스타일 */
.loading-indicator {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.error-message {
  text-align: center;
  padding: 20px;
}
